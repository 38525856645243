import React from "react";
import { graphql } from "gatsby";
import Single from "./Single";
import BoardHeader from "../components/BoardHeader";

function SingleKnowledgeBaseArticle({ data, pageContext }) {
  return (
    <>
      <BoardHeader />
      <div id="board-articles">
        <Single data={data} pageContext={pageContext} type="knowledge_base_article" />
      </div>
    </>
  );
}

export default SingleKnowledgeBaseArticle;

export const query = graphql`
  query($slug: String!) {
    article: nodeKnowledgeBaseArticles(fields: { slug: { eq: $slug } }) {
      drupal_id
      title
      field_date(formatString: "MMMM Do, YYYY")
      field_accordion_title
      field_quicklinks_description
      field_quicklinks_title
      fields {
        slug
      }
      body {
        processed
        summary
      }
      relationships {
        field_category {
          drupal_id
          name
        }
        field_accordion_nodes {
          drupal_id
          title
          field_accordion_tab_content {
            processed
          }
          relationships {
            field_accordion_tab_files {
              drupal_id
              name
              relationships {
                field_media_file {
                  drupal_id
                  uri {
                    url
                  }
                }
              }
            }
          }
        }
        field_quicklinks_files {
          drupal_id
          name
          relationships {
            field_media_file {
              drupal_id
              uri {
                url
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        drupalUrl
      }
    }
  }
`;
